import { mapGetters } from 'vuex';
import { route } from './route';
import { providerAccountRoute } from './route-provider-account';
import { helper } from './helper';
import { url_helper } from './url_helper';
import VueLazyload from 'vue-lazyload';
import Vue from 'vue';
import VTooltip from 'v-tooltip';

Vue.use(VueLazyload);
Vue.use(VTooltip);

export const component = {
  mixins: [route, providerAccountRoute, helper, url_helper],
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    ...mapGetters('app', ['appConfig', 'appErrors', 'laravelData', 'appOldFormInput']),
    ...mapGetters('user', ['userData', 'userExtraData', 'userHubData', 'isAuth', 'targetUserData', 'connectedGames', 'profileSettings', 'socialConnected']),
    ...mapGetters('profileQueueupQbuddy', ['allowedGames']),
    isLightTheme() {
      var theme = '';
      if (this.userData && this.userData.theme) {
        theme = this.userData.theme;
      } else {
        theme = localStorage.getItem('theme');
      }
      if (theme === 'light') {
        return true;
      }
      return false;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResizeWindow);
    this.handleResizeWindow();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResizeWindow);
  },
  methods: {
    handleResizeWindow() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    isProduction() {
      return this.appConfig.serverType.toLowerCase() === 'real';
    },
    isMobile() {
      return this.checkMobileDevice();
    },
    isIpad() {
      return this.checkIpadDevice();
    },
    isIOS() {
      return this.checkIOSDevice();
    },
    isAndroid() {
      return this.checkAndroidDevice();
    },
    isDesktopView() {
      return !this.isMobile() || this.isIpad();
    },
    contentCloudFront(path) {
      return this.appConfig.content_cloud_front + path;
    },
    contentCloudFront_F(path) {
      return this.appConfig.content_cloud_front_f + path;
    },
    contentCloudFrontWithVersion(path) {
      return (
        this.appConfig.content_cloud_front +
        '/assets/' +
        this.appConfig.content_assets_version +
        path
      );
    },
    data_get(target, key, defaultValue = null) {
      let result = Object.assign({}, target);
      if (key == null) {
        return target;
      }
      key = Array.isArray(key) ? key : key.split('.');
      let segment = key.shift();
      while (segment) {
        if (segment === '*') {
          // TODO
        }

        if (result[segment]) {
          result = target[segment];
        } else {
          return defaultValue;
        }
        segment = key.shift();
      }
      return result;
    },
    number_format(number, decimals, dec_point, thousands_sep) {
      // Strip all characters but numerical ones.
      number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
      var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
        dec = typeof dec_point === 'undefined' ? '.' : dec_point,
        s = '',
        toFixedFix = function(n, prec) {
          var k = Math.pow(10, prec);
          return '' + Math.round(n * k) / k;
        };
      // Fix for IE parseFloat(0.55).toFixed(0) = 0;
      s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
      }
      if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
      }
      return s.join(dec);
    },
    getThemeColor() {
      return this.appConfig.isAvglDomain ? '#D93932' : '#E97B26';
    },
    getDefaultAvatar() {
      if (this.appConfig.isAvglDomain) {
        return this.contentCloudFront('/assets/v1/avgl_default_profile.png');
      } else {
        return this.contentCloudFront('/assets/v4/codered/user-tbd.png');
      }
    },
    getHubDefaultLogo(isAvgl) {
      if (isAvgl) {
        return this.contentCloudFront('/assets/v1/avgl_default_profile.png');
      } else {
        return this.contentCloudFront('/assets/v4/codered/icon-1.png');
      }
    },
    getCurrentUrl() {
      return window.location.href;
    },
    replaceByAvatarDefault(e) {
      e.target.src = this.getDefaultAvatar();
    },
    getDefaultEventCardBackground() {
      if (this.appConfig.isAvglDomain) {
        return this.contentCloudFront('/assets/v1/avgl_default_profile.png');
      } else {
        return this.contentCloudFront('/assets/v4/codered/icon-1.png');
      }
    },
    getDefaultHubImage() {
      return this.contentCloudFront('/assets/v1/avgl_default_profile.png');
    },
    getQBuddyDefaultAvatarSquare() {
      return this.contentCloudFront('/assets/queueup/qbuddy-default-avatar-square.jpg');
    },
    replaceByQbuddyDefaultAvatarSquare(e) {
      e.target.src = this.getQBuddyDefaultAvatarSquare();
    },
    getQbuddyDefaultAvatarLandscape() { // landscape
      return this.contentCloudFront('/assets/queueup/qbuddy-default-avatar-landscape.png');
    },
    replaceByQbuddyDefaultAvatarLandscape(e) {
      e.target.src = this.getQbuddyDefaultAvatarLandscape();
    },
    replaceEventCardBackgroundDefault(e) {
      e.target.src = this.getDefaultEventCardBackground();
    },
    replaceByDefaultVideoThumbnail(e) {
      e.target.src = this.contentCloudFrontWithVersion('/rsz_avgl_default_event_icon.png');
    },
    async getUserWallet() {
      try {
        const res = await this.$http.get(this.routeGetUserWallet());
        if (res.data.status === 1) {
          const updateData = {};
          updateData['balance'] = res.data.wallet.balance;
          this.$store.dispatch('user/updateUser', updateData);
          const profileUserExtraData = {};
          profileUserExtraData['wallet'] = res.data.wallet;
          profileUserExtraData['has_processing_transaction'] = false;
          this.$store.dispatch('user/updateProfileUserExtraData', profileUserExtraData);
        }
      } catch (err) {
        console.log('Get Wallet Info Error: ', err);
      }
    },
    disableScrollPage() {
      console.log('disable scroll page');
      console.log(document.documentElement);
      document.documentElement.style['overflow-y'] = 'scroll';
      document.documentElement.style.position = 'fixed';
    },
    enableScrollPage() {
      console.log('enable scroll page');
      document.documentElement.style['overflow-y'] = 'auto';
      document.documentElement.style.position = 'relative';
    },
    checkIsLightTheme() {
      var theme = '';
      if (this.userData && this.userData.theme) {
        theme = this.userData.theme;
      } else {
        theme = localStorage.getItem('theme');
      }
      if (theme === 'light') {
        return true;
      }
      return false;
    },
    guessTimezone() {
      let timezone = this.appConfig.timezone;
      if (timezone) {
        return timezone;
      }

      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // UCBrowser detect wrong timezone so return UTC
      if (navigator.userAgent.indexOf(' UCBrowser/') >= 0 || !timezone || timezone.toLowerCase().includes('undefined') || timezone.toLowerCase().includes('unknown')) {
        timezone = 'UTC';
      }
      this.appConfig['timezone'] = timezone;
      this.$store.dispatch('app/setConfig', this.appConfig);
      return timezone;
    },
    getDecimalPoint(point, allowDecimal = 0) {
      if (allowDecimal) {
        point = parseFloat(point);
        point = point.toFixed(1);
      } else {
        point = parseInt(point);
      }
      return point;
    },
    convertObserverToObj(obj){
      return JSON.parse(JSON.stringify(obj));
    },
    isChrome() {
      return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    },
    /* function to send logs to server in case can't view in console */
    async sendLogToServer(title, content) {
      try {
        const res = await this.$http.post(this.routeSendLog(), {
          title,
          content: JSON.stringify(content),
        }, {
          headers: {
            'X-CSRF-TOKEN': this.appConfig.csrfToken,
          },
        });
        if (res.data.status === 1) {
          console.log(`Send log to server success: ${title}`);
          console.log(content);
        }
      } catch (err) {
        console.log('sendLogToServer Error: ', err);
      }
    },
    base64ToUint8Array(base64) {
      const binaryString = atob(base64.replace(/-/g, '+').replace(/_/g, '/'));
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes;
    },
    arrayBufferToBase64(buffer) {
      return btoa(String.fromCharCode(...new Uint8Array(buffer)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
    },
  },
};
