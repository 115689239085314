import { actions } from './myaccess.actions';
import { getters } from './myaccess.getters';
import { mutations } from './myaccess.mutations';
import { state } from './myaccess.state';

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
};
