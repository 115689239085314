import { mapGetters } from 'vuex';

export const route = {
  computed: {
    ...mapGetters('app', ['appConfig']),
  },
  methods: {
    buildUrl(url, queries = {}) {
      let queryString = '';
      for (const [key, value] of Object.entries(queries)) {
        queryString += key + '=' + value + '&';
      }
      if (queryString.length > 0) {
        queryString = queryString.substr(0, queryString.length - 1);
      }
      if (queryString.length > 0) {
        url += '?' + queryString;
      }
      return url;
    },
    addParameterToURL(url, params) {
      const urlObj = new URL(url);
      for (const [key, value] of Object.entries(params)) {
        urlObj.searchParams.set(key, value);
      }
      return urlObj.toString();
    },
    routePubgCreateEvent(type) {
      return this.appConfig.baseUrl + '/' + type;
    },
    routeUserLink(streamer) {
      return this.appConfig.baseUrl + '/' + streamer;
    },
    routePubgEvents(type) {
      return this.appConfig.baseUrl + '/events/' + (type || '');
    },
    routeConnectSocialStreamingPlatform(queries = {}) {
      return this.buildUrl(
        this.appConfig.baseUrl + '/connect_social_streaming_platform',
        queries
      );
    },
    routePubgEventsFilter(gameFilter) {
      return this.appConfig.baseUrl + '/events/' + (gameFilter + '-tournaments' || '');
    },
    routeProfileCheck(name) {
      return this.appConfig.baseUrl + '/authentic/' + (name || '');
    },
    routeVideos() {
      return this.appConfig.baseUrl + '/videos';
    },
    routeTwitchClips() {
      return this.appConfig.baseUrl + '/twitchclips';
    },
    routeProfile(name) {
      return this.appConfig.baseUrl + '/profile/' + name;
    },
    routeLogout(redirectUrl) {
      return (
        this.appConfig.baseUrl +
        '/logout' +
        (redirectUrl ? '?redirect_url=' + redirectUrl : '')
      );
    },
    routeImportUsersClear() {
      return this.appConfig.baseUrl + '/importUsers/clear';
    },
    routeRegister() {
      return this.appConfig.baseUrl + '/register';
    },
    routeLogin() {
      return this.appConfig.baseUrl + '/login';
    },
    routeHome() {
      return this.appConfig.baseUrl;
    },
    routeTerms() {
      return this.appConfig.baseUrl + '/terms';
    },
    routePrivacy() {
      return this.appConfig.baseUrl + '/privacy';
    },
    routeDMCA() {
      return this.appConfig.baseUrl + '/dmca';
    },
    routeAbout() {
      return this.appConfig.baseUrl + '/about';
    },
    routeOfficialRules() {
      return this.appConfig.baseUrl + '/official-rules';
    },
    routeJobs() {
      return this.appConfig.baseUrl + '/jobs';
    },
    routeContact() {
      return this.appConfig.baseUrl + '/contact';
    },
    routePersonalData() {
      return this.appConfig.baseUrl + '/personal-data';
    },
    routeCareers() {
      return this.appConfig.baseUrl + '/careers';
    },
    routeDropsGetCrownsLeaderboard() {
      return this.appConfig.baseUrl + '/drops/getCrownsLeaderboard';
    },
    routeDropsGetUserDropsInfo() {
      return this.appConfig.baseUrl + '/drops/getUserDropsInfo';
    },
    routeDropsGetDropsPageData() {
      return this.appConfig.baseUrl + '/drops/getDropsPageData';
    },
    routeDropsGetWinnersLeaderboard() {
      return this.appConfig.baseUrl + '/drops/getWinnersLeaderboard';
    },
    routeDropsGetPreviousDrops() {
      return this.appConfig.baseUrl + '/drops/getPreviousDrops';
    },
    routeDropsGetCodeRedWatch() {
      return this.appConfig.baseUrl + '/codered-drops/getWatchTime';
    },
    routeIntelInspriesRegisterPlayer(page, isCreator = 0) {
      if (isCreator) {
        return (
          this.appConfig.baseUrl + '/intel-inspires-register' + (page ? '/page/' + page : '') + '?isCreator=1'
        );
      } else {
        return (
          this.appConfig.baseUrl + '/intel-inspires-register' + (page ? '/page/' + page : '')
        );
      }
    },
    routeIntelInspiresEducation() {
      return this.appConfig.baseUrl + '/intel-inspires-register-education';
    },
    routeIntelInspriesRegisterRecruiter(page) {
      return (
        this.appConfig.baseUrl +
        '/intel-inspires-register-recruiter/' +
        (page || '')
      );
    },
    routeIntelInspriesRegisterScholarship(page) {
      return (
        this.appConfig.baseUrl +
        '/intel-inspires-register-scholarship/' +
        (page || '')
      );
    },
    routeIntelPage() {
      return this.appConfig.baseUrl + '/intel-inspires';
    },
    routeIntelInspiresHub() {
      return this.appConfig.baseUrl + '/intel-inspires-hub';
    },
    routeIntelPageWithSection(section) {
      return this.appConfig.baseUrl + '/intel-inspires#' + section;
    },
    routeIntelPageOverview() {
      return this.appConfig.baseUrl + '/intel-inspires-overview';
    },
    routePokimaneSholarshipPage() {
      return this.appConfig.baseUrl + '/pokimane-scholarship';
    },
    routePokimanePageWithSection(section) {
      return this.appConfig.baseUrl + '/pokimane-scholarship#' + section;
    },
    routeCreatorContests() {
      return this.appConfig.baseUrl + '/creator-contests';
    },
    routeIntelInspriesRecruiters() {
      return this.appConfig.baseUrl + '/intel-inspires-recruiters';
    },
    routeIntelConnectStreamerPlatform(type, redirectUrl = '') {
      if (type === 'discord') {
        return (
          this.appConfig.baseUrl +
          '/discordapp/oauth?join_for_tour=1&redirect_uri=' +
          this.appConfig.baseUrl +
          '/' +
          redirectUrl
        );
      }
      return (
        this.appConfig.baseUrl +
        '/connect_social_streaming_platform?type=' +
        type +
        '&redirect_url=' +
        this.appConfig.baseUrl +
        '/' +
        redirectUrl
      );
    },
    routeIntelConnectStreamerPlatformWithFullRedirect(type, redirectUrl) {
      return (
        this.appConfig.baseUrl +
        '/connect_social_streaming_platform?type=' +
        type +
        '&redirect_url=' +
        redirectUrl
      );
    },
    routeIntelInspriesLogin(isCreator = 0) {
      if (isCreator) {
        return this.appConfig.baseUrl + '/intel-inspires/login?isCreator=1';
      } else {
        return this.appConfig.baseUrl + '/intel-inspires/login';
      }
    },
    routeIntelInspriesPokiLogin(){
      return this.appConfig.baseUrl + '/intel-inspires/login?isPokimane=1';
    },
    routeIntelInspriesDashboard(){
      return this.appConfig.baseUrl + '/intel-inspires-my-dashboard';
    },
    routeCreatorLanding() {
      return this.appConfig.baseUrl + '/creator-contests';
    },
    routeIntelInspriesForgotPassword() {
      return this.appConfig.baseUrl + '/intel-inspires/forgot-password';
    },
    routeSendMailResetPassword() {
      return this.appConfig.baseUrl + '/sendEmailResetPassword';
    },
    routeResetPassword() {
      return this.appConfig.baseUrl + '/intel-inspires/reset-password';
    },
    routePostResetPassword() {
      return this.appConfig.baseUrl + '/reset_password';
    },
    routeDropsGetGeoList() {
      return this.appConfig.baseUrl + '/drops/getLeaderboardGeoList';
    },
    routeDropsPage() {
      return this.appConfig.baseUrl + '/drops';
    },
    routeCheckLiveTournamentOfUser(name) {
      return (
        this.appConfig.baseUrl +
        '/api/profile/check-live-tournament-of-user?name=' +
        name
      );
    },
    routeGetListConnectedGame(username) {
      return (
        this.appConfig.baseUrl +
        '/api/profile/my_activities/get_list_conected_games?name=' +
        username
      );
    },
    routeGetListHubsOfUser(userId) {
      return (
        this.appConfig.baseUrl +
        '/api/profile/my_activities/get_list_hubs_of_user?user_id=' + userId
      );
    },
    routeHub(alias, tab = '') {
      return this.appConfig.baseUrl + '/' + alias + '/' + tab;
    },
    routeHubFollowByDomainUrl(domainUrl, alias) {
      return domainUrl + '/' + alias;
    },
    routeGetListTournamentOfUser(userId, gameType = '', page) {
      if (gameType !== '') {
        return (
          this.appConfig.baseUrl +
          '/api/profile/my_activities/get_list_tournament_of_user?page=' +
          page +
          '&userId=' +
          userId +
          '&gameType=' +
          gameType
        );
      } else {
        return (
          this.appConfig.baseUrl +
          '/api/profile/my_activities/get_list_tournament_of_user?page=' +
          page +
          '&userId=' +
          userId
        );
      }
    },
    routeSaveUserProfile() {
      return (
        this.appConfig.baseUrl + '/api/user/save-profile-setting'
      );
    },
    routeGetListVideoOfUser(userId, stringGetData) {
      return (
        this.appConfig.baseUrl +
        '/api/profile/my_activities/get_all_videos_of_user?user_id=' + userId + stringGetData
      );
    },
    routeLikeVideo() {
      return this.appConfig.baseUrl + '/likevideo';
    },
    routeGetListGameByOrder() {
      return this.appConfig.baseUrl + '/api/get-list-game-by-order';
    },
    routeLandingGetListBanner() {
      return this.appConfig.baseUrl + '/get_list_banner';
      /* return this.buildUrl(this.appConfig.baseUrl + '/get_list_banner', {
        c: 'y',
        ct: 'a',
        cti: 60 * 60 * 7,
      });*/
    },
    routeGetUsersByName(name) {
      return `${this.appConfig.baseUrl}/api/v1/usersByName?name=${name}`;
    },
    routeLandingGetListBannerMobile() {
      return this.appConfig.baseUrl + '/get_list_banner_mobile';
    },
    routeFilterEventV2(testMode) {
      return this.buildUrl(
        this.appConfig.baseUrl + '/qualifiers/filter_event_v2',
        {
          test_mode: testMode || 0,
          c: 'y',
          ct: 'g',
          cti: 60,
        }
      );
    },
    routeFilterEventByGame(game) {
      return this.buildUrl(
        this.appConfig.baseUrl + '/qualifiers/filter_event_by_game',
        {
          game: game || '',
        }
      );
    },
    routeGetTrendingHubs() {
      return this.appConfig.baseUrl + '/api/get-trending-hubs';
    },
    routeGetRecentNews() {
      return this.appConfig.baseUrl + '/api/get-recent-news';
    },
    routeLanding() {
      return this.appConfig.baseUrl;
    },
    routeBoomTV() {
      return this.appConfig.boomUrl + '/landing';
    },
    routeAvgl() {
      return this.appConfig.avglUrl + '/landing';
    },
    routeQueueup() {
      return this.appConfig.queueupUrl;
    },
    routeMyHub() {
      return this.appConfig.baseUrl + '/my-hub';
    },
    routeRequestHub() {
      return this.appConfig.baseUrl + '/request-hub';
    },
    routePubgTournament(codered, id) {
      return this.appConfig.baseUrl + '/' + codered + (id ? '/' + id : '');
    },
    routePubgShowQualifiers(qualifiers, id) {
      return this.appConfig.baseUrl + '/' + qualifiers + (id ? '/' + id : '');
    },
    routeAllFAQ() {
      return this.appConfig.baseUrl + '/all-faq';
    },
    routeDownload() {
      return this.appConfig.baseUrl + '/download';
    },
    routeHighSchoolSupport() {
      return this.appConfig.baseUrl + '/highschool-support';
    },
    routeHighSchool() {
      return this.appConfig.baseUrl + '/highschool';
    },
    routeHighSchoolLanding(partner) {
      let url = this.appConfig.baseUrl + '/highschool';
      switch (partner) {
        case 'intel':
          url = 'https://www.intel.com/content/www/us/en/homepage.html';
          break;
        case 'cdw':
          url = 'https://www.cdw.com/';
          break;
        case 'vnn':
          url = 'https://vnnsports.net/';
          break;
        case 'stem':
          url = 'https://stem.org/';
          break;
        case 'eh':
          url = 'https://www.twitch.tv/nickeh30';
          break;
        case 'g2':
          url = 'https://g2esports.com/';
          break;
        case 'complexity':
          url = 'https://complexity.gg/';
          break;
        default:
          url = this.appConfig.baseUrl + '/highschool';
      }
      return url;
    },
    routeHighschoolHub() {
      return 'https://avgl.org/highschoolesports';
    },
    routeGetListSupportedGame() {
      return this.appConfig.baseUrl + '/highschool/get-supported-games';
    },
    routeGetAllHighSchoolBrands() {
      return this.appConfig.baseUrl + '/highschool/get-all-brands';
    },
    routeHighSchoolLandingStudent() {
      return this.appConfig.baseUrl + '/highschool/student';
    },
    routeHighSchoolLandingAdmin() {
      return this.appConfig.baseUrl + '/highschool/faculty';
    },
    routeHighSchoolLandingAdminSection(section) {
      return this.appConfig.baseUrl + '/highschool/faculty#' + section;
    },
    routeHighSchoolRequest() {
      return this.appConfig.baseUrl + '/highschool/request';
    },
    routeGetUpcomingIntelInspiresEvent() {
      return (
        this.appConfig.baseUrl +
        '/highschool-support/upcoming-intel-inspires-event'
      );
    },
    routeFAQ() {
      return this.appConfig.baseUrl + '/faq';
    },
    routeExtensionFAQ() {
      return this.appConfig.baseUrl + '/extension-faq';
    },
    routeRaffleFAQ() {
      return this.appConfig.baseUrl + '/raffle-faq';
    },
    routeSubTournamentFAQ() {
      return this.appConfig.baseUrl + '/sub-tournament-faq';
    },
    routeTournamentFAQ() {
      return this.appConfig.baseUrl + '/tournament-faq';
    },
    routeQBuddyFAQ() {
      return this.appConfig.queueupUrl + '/qbuddy-faq';
    },
    routeGetFAQStatuses() {
      return this.buildUrl(this.appConfig.baseUrl + '/api/get-faq-statuses', {
        c: 'y',
        ct: 'a',
        cti: 60 * 60 * 7,
      });
    },
    routeGetTopHubsAndQbuddies() {
      return this.appConfig.baseUrl + '/queueup-menu/get-top-hubs-and-qbuddies';
    },
    routeMyProfile(tab) {
      if (tab) {
        return this.appConfig.baseUrl + '/profile#' + tab;
      } else {
        return this.appConfig.baseUrl + '/profile';
      }
    },
    routeSubscriptions() {
      return this.appConfig.baseUrl + '/subscriptions';
    },
    routeGameLogin(type, url = '', params = {}) {
      let redirect_uri = url;
      if (url === '') {
        redirect_uri = window.location.href;
      }
      var oauthParams = {
        is_claim: 1,
        source: 0,
        redirect_uri: encodeURIComponent(redirect_uri),
        type: type,
        g: 1,
      };
      oauthParams = Object.assign(oauthParams, params);

      return this.buildUrl(this.appConfig.baseUrl + '/oauth', oauthParams);
    },
    routeConnectStreamerPlatform(type, redirectUrl) {
      return (
        this.appConfig.baseUrl +
        '/connect_social_streaming_platform?type=' +
        type +
        '&redirect_url=' +
        redirectUrl
      );
    },
    routeConnectTwitter(redirectUrl) {
      return (
        this.appConfig.baseUrl +
        '/connect_twitter?redirect_url=' +
        this.appConfig.baseUrl +
        '/' +
        redirectUrl
      );
    },
    routeGetBoomAlerts() {
      return this.buildUrl(this.appConfig.baseUrl + '/api/get-boom-alerts', {
        c: 'y',
        ct: 'a',
        cti: 60 * 60 * 7,
      });
    },
    routeGetListActivitiesOfUserInOverviewTab(page, name) {
      return (
        this.appConfig.baseUrl +
        '/api/profile/overview/get_list_activity_history?page=' +
        page +
        '&name=' +
        name
      );
    },
    routeGetListPinnedActivitiesOfUserInOverviewTab(page, name) {
      return (
        this.appConfig.baseUrl +
        '/api/profile/overview/get_list_pinned_activity_history?page=' +
        page +
        '&name=' +
        name
      );
    },
    routeGetActivityRegisterCurrentlyOfUser(name) {
      return (
        this.appConfig.baseUrl +
        '/api/profile/overview/get_activity_register_currently?name=' + name
      );
    },
    routeSubmitSubTourneys() {
      return this.appConfig.baseUrl + '/sub_tourneys/saveInformation';
    },
    routeCheckTwitchUsernameIsExist(twitchUsername) {
      return (
        this.appConfig.baseUrl +
        '/sub_tourneys/checkTwitchUsernameIsExist?twitch_username=' +
        twitchUsername
      );
    },
    routeGetListMegaCakesData(page = 1) {
      return (
        this.appConfig.baseUrl +
        '/api/profile/settings/get_list_mega_cakes_data?page=' +
        page
      );
    },
    routeUpdatePublicDataOfUser() {
      return (
        this.appConfig.baseUrl + '/api/profile/settings/update_public_data'
      );
    },
    routeChangePrimaryStreamingPlatform() {
      return this.appConfig.baseUrl + '/change-primary-streaming-platform';
    },
    routeChangeDisplayDiscordPublicly() {
      return this.appConfig.baseUrl + '/change-display-discord-publicly';
    },
    routeSubmitDisconnectSocialAccount() {
      return this.appConfig.baseUrl + '/remove-social-connection';
    },
    routeGetNumberCrownsAndCakesOfUser(name) {
      return (
        this.appConfig.baseUrl +
        '/api/profile/get_number_crowns_and_cake_of_user?name=' +
        name
      );
    },
    routeGetLeaderboardInProfile(offset, name, game = '', centerUser = 0) {
      return (
        this.appConfig.baseUrl +
        '/api/profile/getLeaderboard?name=' +
        name +
        '&offset=' +
        offset +
        '&game=' +
        game +
        '&center_user=' +
        centerUser
      );
    },
    routeRecruitPlayer() {
      return this.appConfig.baseUrl + '/recruit-player-filter';
    },
    routeRecruitPlayerMark() {
      return this.appConfig.baseUrl + '/recruit-player/save-user-recruit';
    },
    routeRemoveRecruitPlayer() {
      return this.appConfig.baseUrl + '/recruit-player/remove-user-recruit';
    },
    routeGetListConnectedSocialAccount(username) {
      return (
        this.appConfig.baseUrl +
        '/api/profile/get_list_connected_social_account?name=' +
        username
      );
    },
    routeGetProfileSettingsOfUser(username) {
      return (
        this.appConfig.baseUrl +
        '/api/profile/get_profile_setting_of_user?name=' +
        username
      );
    },
    routeGetUserWallet() {
      return `${this.appConfig.baseUrl}/api/profile/get_user_wallet`;
    },
    routeGetWalletTransactionHistory(queries) {
      return this.buildUrl(
        `${this.appConfig.baseUrl}/api/profile/get_wallet_transaction_history`,
        queries
      );
    },
    routeProfileSetting() {
      return `${this.appConfig.baseUrl}/profile#settings`;
    },
    routeGetWithdrawalRecipient() {
      return `${this.appConfig.baseUrl}/api/wallet/withdrawalRecipient/get`;
    },
    routeUpdateWithdrawalRecipient(paypalEmail) {
      return `${this.appConfig.baseUrl}/api/wallet/withdrawalRecipient/update?paypalEmail=${paypalEmail}`;
    },
    routeCreatePayout(payoutObject = {}) {
      return this.buildUrl(
        `${this.appConfig.baseUrl}/api/wallet/createPayout`,
        payoutObject
      );
    },
    routeCreatePaypalOrder(orderObject = {}) {
      return this.buildUrl(
        `${this.appConfig.baseUrl}/api/wallet/createOrder`,
        orderObject
      );
    },
    routeCheckPromoCodeOrder(orderObject = {}) {
      return this.buildUrl(
        `${this.appConfig.baseUrl}/api/provider-service-orders/check-promo-code`,
        orderObject
      );
    },
    routeSubmitPhoneNumber() {
      return `${this.appConfig.baseUrl}/submit-phone-number`;
    },
    routeSubmitVerificationCode() {
      return `${this.appConfig.baseUrl}/submit-verification`;
    },
    // routeCheckOrderAvailability() {
    //   return `${this.appConfig.baseUrl}/api/provider-service-orders/check-availability`;
    // },
    routeCheckProviderOrderAvailability() {
      return `${this.appConfig.baseUrl}/api/provider-service-orders/check-provider-availability`;
    },
    routeCreateServiceOrder() {
      return `${this.appConfig.baseUrl}/api/provider-service-orders/create`;
    },
    routeCancelTransaction(transactionId) {
      return `${this.appConfig.baseUrl}/api/wallet/cancelTransaction?transactionId=${transactionId}`;
    },
    routeWalletUpdate(orderId) {
      return `${this.appConfig.baseUrl}/api/profile/update_paypal_wallet_funds_purchase?orderId=${orderId}`;
    },
    routeGetWalletUpcomingEvents(limit = 3) {
      return `${this.appConfig.baseUrl}/api/profile/get_upcoming_events?limit=${limit}`;
    },
    routeGetWalletIncomingRequests() {
      return `${this.appConfig.baseUrl}/api/profile/getIncomingFundsRequests`;
    },
    routeAcceptFundsRequest(requestId) {
      return `${this.appConfig.baseUrl}/api/profile/acceptFundsRequest?requestId=${requestId}`;
    },
    routeDenyFundsRequest(requestId) {
      return `${this.appConfig.baseUrl}/api/profile/denyFundsRequest?requestId=${requestId}`;
    },
    routeCreateGiftFundsToUserRequest(queries = {}) {
      /* requestRecipientId, amount, message*/
      return this.buildUrl(
        `${this.appConfig.baseUrl}/api/profile/createGiftFundsToUserRequest`,
        queries
      );
    },
    routeCreateReceiveFundsFromUserRequest(queries = {}) {
      /* requestRecipientId, amount, message*/
      return this.buildUrl(
        `${this.appConfig.baseUrl}/api/profile/createReceiveFundsFromUserRequest`,
        queries
      );
    },
    routeSubTourneys() {
      return this.appConfig.baseUrl + '/sub_tourneys';
    },
    routeGetLinkShareTwitter() {
      return this.appConfig.baseUrl + '/get-link-share-twitter-intel-inspires';
    },
    routeGetQueueupTwitterShareLink() {
      return this.appConfig.baseUrl + '/api/queueup-get-twitter-share-link';
    },
    routeHowItWork(stage) {
      return this.appConfig.baseUrl + '/how-it-works#' + stage;
    },
    routePrifleUser(name) {
      return this.appConfig.baseUrl + '/profile/' + name;
    },
    routeExportCsvRecruiterPage() {
      return this.appConfig.baseUrl + '/recruit-player-export-to-csv';
    },
    async routeAddFund(
      amount,
      showChatAfterRedirect = false,
      returnUrl,
      cancelUrl
    ) {
      let returnURL = returnUrl || window.location.href;
      let cancelURL = cancelUrl || window.location.href;
      if (showChatAfterRedirect) {
        returnURL = this.addParameterToURL(returnURL, {
          showChat: true,
        });
        cancelURL = this.addParameterToURL(cancelURL, {
          showChat: true,
        });
      }
      returnURL = returnURL.split('&token=')[0];
      cancelURL = cancelURL.split('&token=')[0];
      returnURL = encodeURIComponent(returnURL);
      cancelURL = encodeURIComponent(cancelURL);
      try {
        const response = await this.$http.post(
          this.routeCreatePaypalOrder({
            amount: amount,
            return_url: returnURL,
            cancel_url: cancelURL,
          })
        );
        if (response.data.status === 1 && response.data.order.approveUrl) {
          window.location.href = response.data.order.approveUrl;
        }
      } catch (error) {
        console.log('Add Fund Error', error);
        throw error;
      }
    },
    routeConnectPhoneNumber() {
      return this.appConfig.baseUrl + '/event-notifications';
    },
    routeDisconnectPhoneNumber() {
      return this.appConfig.baseUrl + '/user/disconnect-phone-number';
    },
    routeRemoveGameName() {
      return this.appConfig.baseUrl + '/remove_game_name';
    },
    routeDisconnectSms() {
      return this.appConfig.baseUrl + '/user/disconnect-phone-number';
    },
    routeGetProfileBanner() {
      return this.appConfig.baseUrl + '/api/profile/get_profile_banner';
    },
    routeGetListEventCalendar() {
      return this.appConfig.baseUrl + '/event/get-list-event-calendar';
    },
    routeVerifyNewAccount() {
      return this.appConfig.baseUrl + '/register_verify/verify_new_account';
    },
    routeSubmitVerificationNewAccount() {
      return this.appConfig.baseUrl + '/register_verify/submit_verification_new_account';
    },
    routeInviteAvglViaDiscord() {
      return this.appConfig.baseUrl + '/discordapp/oauth?redirect_uri=' + location.href + '&allow_create_channel=1';
    },
    routeInviteAvglViaTwitter() {
      return this.appConfig.baseUrl + '/connect_social_streaming_platform?type=twitter&redirect_url=' + location.href + '&allow_tweet=1';
    },
    routeCreateEventInAdminApp() {
      return this.appConfig.baseUrl + '/create';
    },
    routeGetTournamentHighlights(tournamentId) {
      return `${this.appConfig.baseUrl}/api/events/${tournamentId}/highlights`;
    },
    routeSubmitUserAndPaymentInfo() {
      return this.appConfig.baseUrl + '/qualifiers/submit-user-and-payment-info';
    },
    routeEnterGameName() {
      return this.appConfig.baseUrl + '/qualifiers/enter-game-name';
    },
    routeGetListCoderedBanners() {
      return this.appConfig.baseUrl + '/api/events/get_list_codered_banners';
    },
    routeGetListListFilterGameByCategory(category = '') {
      return this.appConfig.baseUrl + '/api/events/get_list_filter_game_by_category?category=' + category;
    },
    routeCreateEvent() {
      return this.appConfig.baseUrl + '/create';
    },
    routeSubmitHubDropQuest() {
      return this.appConfig.baseUrl + '/api/hub/hub_drop/submit_quest';
    },
    routeGetHubDropQuest() {
      return this.appConfig.baseUrl + '/api/hub/hub_drop/get_quest_data';
    },
    routeGetListDropLeaderboard() {
      return this.appConfig.baseUrl + '/api/hub/hub_drop/get_list_leaderboard';
    },
    routeGetListDrop() {
      return this.appConfig.baseUrl + '/api/hub/hub_drop/get_list_drop';
    },
    routeRedirectHubTab(alias, tab) {
      return this.appConfig.baseUrl + '/' + alias + '/' + tab;
    },
    routeIntelRegister() {
      return this.appConfig.baseUrl + '/intel-inspires-register';
    },
    routeSendLog() {
      return `${this.appConfig.baseUrl}/send-log`;
    },
  },
};
