// export { default } from './store';
import Vue from 'vue';
import Vuex from 'vuex';
import AppModule from './modules/app/app.module';
import UserModule from './modules/user/user.module';
import NotificationModule from './modules/notifications/notifications.module';
import RewardModule from './modules/rewards/rewards.module';
import TournamentModule from './modules/tournament/tournament.module';
import HubModule from './modules/hubs/hub.module';
import ScrimmageModule from './modules/scrimmage/scrimmage.module';
import AdminCreatorModule from './modules/admin-creator/admin-creator.module';
import MyAccessModule from './modules/myaccess/myaccess.module';

Vue.use(Vuex);
Vue.config.devtools = true;

// // https://webpack.js.org/guides/dependency-management/#requirecontext
// const modulesFiles = require.context('./modules', false, /\.js$/);
//
// // you do not need `import app from './modules/app'`
// // it will auto require all vuex module from modules file
// const modules = modulesFiles.keys().reduce((modules, modulePath) => {
//   // set './app.js' => 'app'
//   const moduleName = camelCase(modulePath.replace(/^\.\/(.*)\.\w+$/, '$1'));
//   const value = modulesFiles(modulePath);
//   modules[moduleName] = value.default;
//   return modules;
// }, {});

const modules = {
  app: AppModule,
  user: UserModule,
  notifications: NotificationModule,
  rewards: RewardModule,
  tournament: TournamentModule,
  hub: HubModule,
  scrimmage: ScrimmageModule,
  adminCreator: AdminCreatorModule,
  myAccess: MyAccessModule,
};

const store = new Vuex.Store({
  modules,
});

export default store;

// export store;
